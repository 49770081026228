/* eslint-disable react/prop-types */
'use client';
import React, { createContext, useContext, useState, useEffect } from 'react';
import Script from 'next/script';

export const JsonLdContext = createContext({});

interface Props {
  children: React.ReactElement;
}
export const JsonLdProvider = ({ children }: Props) => {
  const [items, setItems] = useState<{ [key: string]: any }>({});
  const [faqPage, setFaqPage] = useState<any>({
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    '@id': `#faqPage`,
    'mainEntity': []
  });
  const [rest, setRest] = useState<any[]>([]);

  const addJsonLdItem = (item: any) => {
    setItems((prev: any) => ({ ...prev, [item['@id']]: item }));
  };

  const addJsonLdItems = (items: any[]) => {
    const obj = items.reduce((prev, curr) => {
      return { ...prev, [curr['@id']]: curr };
    }, {});
    setItems((prev: any) => ({ ...prev, ...obj }));
  };

  useEffect(() => {
    const keys = Object.keys(items);
    const faqItems: any[] = [];
    const rest = [];
    for (let key of keys) {
      if (items[key]['@type'] === 'Question') {
        faqItems.push(items[key]);
      } else {
        rest.push(items[key]);
      }
    }
    setFaqPage((prev: any) => ({ ...prev, mainEntity: faqItems }));
    setRest(rest);
  }, [items]);

  return (
    <JsonLdContext.Provider value={{ addJsonLdItem, addJsonLdItems }}>
      {children}
      {!!faqPage?.mainEntity?.length && (
        <Script
          type="application/ld+json"
          id={faqPage['@id']}
          dangerouslySetInnerHTML={{ __html: JSON.stringify(faqPage) }}
        />
      )}
      {rest?.map((o) => (
        <Script
          key={o['@id']}
          type="application/ld+json"
          id={o['@id']}
          dangerouslySetInnerHTML={{ __html: JSON.stringify(o) }}
        />
      ))}
    </JsonLdContext.Provider>
  );
};

export const useJsonLd = () => useContext<{ [key: string]: any }>(JsonLdContext);

export default JsonLdProvider;
